import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Col, Container, Row } from 'react-bootstrap';
import LinkButton from '../LinkButton/LinkButton';
import * as styles from './planning.module.scss';
import c from 'classnames';

import {
  BubbleIcon,
  KitchenIcon,
  MapIcon,
  RollerIcon
} from '../../images/index';

const Planning = ({ planning }) => {
  const intl = useIntl();

  return (
    <Container className={styles.planningContainer}>
      <Row className={styles.section}>
        <Col className="text-center">
          <h3 className="title">{planning.title}</h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={3} className="text-center">
          <BubbleIcon className={styles.icon} />
          <p>{planning.paragraph1}</p>
        </Col>
        <Col md={3} className="text-center">
          <MapIcon className={styles.icon} />
          <p>{planning.paragraph2}</p>
        </Col>
        <Col md={3} className="text-center">
          <KitchenIcon className={styles.icon} />
          <p>{planning.paragraph3}</p>
        </Col>
        <Col md={3} className="text-center">
          <RollerIcon className={styles.icon} />
          <p>{planning.paragraph4}</p>
        </Col>
      </Row>
      <Row>
        <Col className={styles.buttonColumn}>
          <p className="button">
            <LinkButton
              path="/contact"
              title={intl.formatMessage({ id: 'button.work_together' })}
            />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Planning;
