import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import c from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import StackGrid, { transitions } from 'react-stack-grid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LinkButton from '../LinkButton/LinkButton';
import * as styles from './portfolio.module.scss';

const Portfolio = ({ portfolio }) => {
  const { scaleDown } = transitions;
  const intl = useIntl();
  const options = {
    caption: {
      showCaption: false
    }
  };

  return (
    <Container fluid className={c(styles.portfolioContainer)}>
      <Row>
        <Col className={c(styles.column, 'text-center')}>
          <h3 className="title">{portfolio.title}</h3>
        </Col>
      </Row>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <StackGrid
            columnWidth={300}
            gutterWidth={20}
            gutterHeight={20}
            appear={scaleDown.appear}
            appeared={scaleDown.appeared}
            enter={scaleDown.enter}
            entered={scaleDown.entered}
            leaved={scaleDown.leaved}
            monitorImagesLoaded={true}
          >
            {portfolio.images &&
              portfolio.images.map((image, index) => (
                <GatsbyImage
                  key={index}
                  image={getImage(image.image)}
                  alt={image.imageAlt}
                  className="image"
                />
              ))}
          </StackGrid>
        </SRLWrapper>
      </SimpleReactLightbox>
      <Row>
        <Col className={styles.buttonColumn}>
          <p className="button">
            <LinkButton
              path="/enterior/portfolio"
              title={intl.formatMessage({ id: 'button.next' })}
            />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Portfolio;
