import * as React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Hero from '../components/Hero/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';
import Mission from '../components/Mission/Mission';
import Planning from '../components/Planning/Planning';
import Portfolio from '../components/Portfolio/Portfolio';
import Quote from '../components/Quote/Quote';

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const seoData = {
    markdownRemark: {
      frontmatter: {
        en: data.markdownRemark.frontmatter.en.seo,
        hu: data.markdownRemark.frontmatter.hu.seo
      }
    }
  };
  const intl = useIntl();
  const langData = intl.locale === 'hu' ? frontmatter.hu : frontmatter.en;

  return (
    <Layout>
      <SEO seoData={seoData} />
      <Hero hero={langData.hero} />
      <Mission mission={langData.mission} />
      <Planning planning={langData.planning} />
      <Portfolio portfolio={langData.portfolio} />
      <Quote quote={langData.quote} />
    </Layout>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
      frontmatter {
        en {
          hero {
            title1
            title2
            title3
            image1 {
              childImageSharp {
                gatsbyImageData(width: 1650)
              }
            }
            image2 {
              childImageSharp {
                gatsbyImageData(width: 1650)
              }
            }
            image3 {
              childImageSharp {
                gatsbyImageData(width: 1650)
              }
            }
            image1Alt
            image2Alt
            image3Alt
          }
          mission {
            paragraph
            title
            image {
              childImageSharp {
                gatsbyImageData(height: 398)
              }
            }
            imageAlt
          }
          planning {
            paragraph1
            paragraph2
            paragraph3
            paragraph4
            title
          }
          portfolio {
            title
            images {
              image {
                childImageSharp {
                  gatsbyImageData(width: 2048)
                }
              }
              imageAlt
            }
          }
          quote {
            paragraph
          }
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
        hu {
          hero {
            title1
            title2
            title3
            image1 {
              childImageSharp {
                gatsbyImageData(width: 1650)
              }
            }
            image2 {
              childImageSharp {
                gatsbyImageData(width: 1650)
              }
            }
            image3 {
              childImageSharp {
                gatsbyImageData(width: 1650)
              }
            }
            image1Alt
            image2Alt
            image3Alt
          }
          mission {
            paragraph
            title
            image {
              childImageSharp {
                gatsbyImageData(height: 398)
              }
            }
            imageAlt
          }
          planning {
            paragraph1
            paragraph2
            paragraph3
            paragraph4
            title
          }
          portfolio {
            title
            images {
              image {
                childImageSharp {
                  gatsbyImageData(width: 2048)
                }
              }
              imageAlt
            }
          }
          quote {
            paragraph
          }
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
      }
    }
  }
`;
