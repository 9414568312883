import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import c from 'classnames';
import * as styles from './quote.module.scss';
import { OpenQuote, CloseQuote } from '../../images/index';

const Quote = ({ quote }) => {
  return (
    <Container fluid className={styles.quoteContainer}>
      <Row className={styles.section}>
        <Col md={4} className={c(styles.openQuote, 'mt-5')}>
          <OpenQuote />
        </Col>
        <Col md={4} className={styles.column}>
          <h1>{quote.paragraph}</h1>
        </Col>
        <Col md={4} className={c(styles.closeQuote, 'mb-5')}>
          <CloseQuote />
        </Col>
      </Row>
    </Container>
  );
};

export default Quote;
