import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import * as styles from './hero.module.scss';
import { LineIcon } from '../../images/index';

const Hero = ({ hero }) => {
  const image1 = hero.image1;
  const image2 = hero.image2;
  const image3 = hero.image3;

  return (
    <div className={styles.carouselItem}>
      <Carousel
        controls={false}
        fade={true}
        touch={true}
        className={styles.hero}
      >
        <Carousel.Item>
          <Container fluid className="p-0">
            <Row>
              <Col md={8} className={styles.imageContainer}>
                <GatsbyImage
                  image={getImage(image1)}
                  alt={hero.image1Alt}
                  className="image"
                />
              </Col>
              <Col md={4} className={styles.caption}>
                <div>
                  {hero.title1.split('\n\n').map((line, index) => (
                    <h2 className={styles.heroText} key={index}>
                      {line}
                    </h2>
                  ))}
                  <p className={styles.captionLine}>
                    <LineIcon />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item>
          <Container fluid className="p-0">
            <Row>
              <Col md={8} className={styles.imageContainer}>
                <GatsbyImage
                  image={getImage(image2)}
                  alt={hero.image2Alt}
                  className="image"
                />
              </Col>
              <Col md={4} className={styles.caption}>
                <div>
                  {hero.title2.split('\n\n').map((line, index) => (
                    <h2 className={styles.heroText} key={index}>
                      {line}
                    </h2>
                  ))}
                  <p className={styles.captionLine}>
                    <LineIcon />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid className="p-0">
            <Row>
              <Col md={8} className={styles.imageContainer}>
                <GatsbyImage
                  image={getImage(image3)}
                  alt={hero.image3Alt}
                  className="image"
                />
              </Col>
              <Col md={4} className={styles.caption}>
                <div>
                  {hero.title3.split('\n\n').map((line, index) => (
                    <h2 className={styles.heroText} key={index}>
                      {line}
                    </h2>
                  ))}{' '}
                  <p className={styles.captionLine}>
                    <LineIcon />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Hero;
