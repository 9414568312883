import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Col, Container, Row } from 'react-bootstrap';
import Line from '../Line/Line';
import LinkButton from '../LinkButton/LinkButton';
import * as styles from './mission.module.scss';

const Mission = ({ mission }) => {
  const image = mission.image;
  const intl = useIntl();

  return (
    <Container>
      <Row className={styles.section}>
        <Col md={5}>
          <h3 className="title">{mission.title}</h3>
          <p>{mission.paragraph}</p>
          <p className="button mb-3">
            <LinkButton
              path="/about"
              title={intl.formatMessage({ id: 'button.next' })}
            />
          </p>
        </Col>
        <Col md={7} className="text-center">
          <GatsbyImage image={getImage(image)} alt={mission.imageAlt} />
        </Col>
      </Row>
      <Line />
    </Container>
  );
};

export default Mission;
